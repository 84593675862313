import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { GatsbyImage } from "gatsby-plugin-image";
import scrollToElement from '../../helpers/scrollToElement';

const Metabolism4 = (props) => (
    <section class="metabolism-4">
        <Container>
            <h2 className="text-center">Postaw na kompleksowe działanie</h2>
        </Container>
        <div className="half-black">
            <Container>
                <Row noGutters>
                    <Col xs="12" md="6">
                        <GatsbyImage image={props.body} />
                    </Col>
                    <Col xs="12" md="6">
                        <GatsbyImage image={props.mind} />
                    </Col>
                </Row>
            </Container>
        </div>
        <Container>
            <Row noGutters>
                <Col xs="12" md="6">
                    <GatsbyImage image={props.libido} />
                </Col>
                <Col xs="12" md="6">
                    <div className="text-block">
                        <p>Ciało, umysł i zmysły to bardzo istotne sfery naszego życia. Sfery, które wzajemnie na siebie wpływają! Gdy jedna z nich nie funkcjonuje prawidłowo, zaczynają też cierpieć inne… Zadbaj więc o to, żeby działały ze sobą w równowadze! Jak to osiągnąć? Najlepszy sposób to kompleksowe podejście - dieta, treningi i naturalna suplementacja.
                        </p>
                        <p>
                            <strong>CIAŁO</strong> - Popraw kondycję, wydolność i wygląd swojego organizmu. Czuj się dobrze we własnej skórze!
                        </p>
                        <p>
                            <strong>UMYSŁ</strong> - Zapewnia Ci wewnętrzną równowagę i odpowiada za samopoczucie. To głównie od niego zależy, jak reagujemy na kryzysowe sytuacje - poddajemy się czy walczymy dalej!
                        </p>
                        <p>
                            <strong>ZMYSŁY</strong> - Ich poziom zależy od stanu naszego ciała i umysłu! Zdrowa dieta i ruch to sposób na to, żeby poczuć jeszcze większą radość z miłości.
                        </p>
                        <br />
                        <div className="text-center">
                            <Button
                                color="blue"
                                onClick={() =>
                                    scrollToElement(
                                        document.getElementById('buy')
                                    )
                                }
                            >
                                Kup teraz
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
);

export default Metabolism4;