import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import level_white from '../../images/icons/level_white.svg';
import {TRAINING_PROGRAMS, TRAINING_DAYS, DIET_COUNT} from '../../constants';

import { GatsbyImage } from "gatsby-plugin-image";

const Metabolism9 = (props) => (
    <section className="metabolism-9">
        <div className="position-relative">
            <div className="half-red">
                <img src={level_white} className="d-none d-sm-block" height="184" alt="" />
            </div>
            
            <Container>
                <Row>
                    <Col xs="12" md="5" >
                        <GatsbyImage image={props.app} className="mb-5" />
                    </Col>
                    <Col xs="12" md={{size: 6, offset: 1}}>
                        <div className="text-block">
                            <h2>Dieta i treningi <span class="info">30 DNI</span></h2>
                            <p>{DIET_COUNT} diety i {TRAINING_PROGRAMS} programów treningowych do wyboru. To ponad {TRAINING_DAYS} różnych dni treningowych i dziesiątki tysięcy potraw! Wybierz taką kombinację, która najlepiej odpowiada Twoim aktualnym potrzebom!</p>
                            <p>Zdrowy styl życia to niekończąca się przygoda. Przygoda, dzięki której stajesz się najlepszą wersją samego siebie! Zanim dotrzesz na szczyt, pokonujesz poszczególne etapy. Diet & Training by Ann to aplikacja, która sprawdzi się na każdym etapie Twojej ścieżki!</p>                          
                        </div>
                    </Col>
                    
                </Row>
            </Container>
        </div>

    </section>
); 
   
export default Metabolism9;
