import React from 'react';
import { Container, Button } from 'reactstrap';
import scrollToElement from '../../helpers/scrollToElement';

const Metabolism7 = () => (
    <section className="metabolism-7">
        <Container>
            <div className="cta">
                <h3>PO CO CZEKAĆ?</h3>
                <h2>JUŻ DZIŚ WEJDŹ NA WYŻSZY LEVEL!</h2>
                <Button
                    color="blue"
                    onClick={() =>
                        scrollToElement(
                            document.getElementById('buy')
                        )
                    }
                >
                    Kup swój pakiet
                </Button>
            </div>
        </Container>
    </section>
); 
   
export default Metabolism7;
