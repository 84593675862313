import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { GatsbyImage } from "gatsby-plugin-image";
import scrollToElement from '../../helpers/scrollToElement';

const Metabolism1 = (props) => (
    <section className="metabolism-1">
        <div className="half-white">
            <Container>
                <Row className="align-items-center">
                    <Col md="6" className="pt-5">
                        <h2>Fat Burner to naturalny zestaw stworzony z myślą o Tobie</h2>
                        <p>
                        Zdrowy styl życia to niekończąca się przygoda. Zobacz, co jeszcze przed Tobą! Wspólnie wskoczmy na następny, wyższy level.<br/>
                        - Anna Lewandowska</p>
                        <p>Diet & Training by Ann to aplikacja dla ludzi, którzy lubią wyzwania. Połącz jej zalety - indywidualnie dopasowaną dietę i treningi z naturalną suplementacją. Ciesz się efektami, o jakich inni mogą tylko pomarzyć!</p>
                        <Button
                            color="blue"
                            onClick={() =>
                                scrollToElement(
                                    document.getElementById('buy')
                                )
                            }
                        >
                            Kup teraz
                        </Button>
                    </Col>
                    <Col md={{size: 5, offset: 1}} className="text-center">
                        <GatsbyImage image={props.meta} />
                        <div className="tags">
                            <span className="tag">CLASSIC</span>
                            <span className="tag">VEGE</span>
                            <span className="tag">SPORT</span>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </section>
);

export default Metabolism1;
