import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import heart from '../../images/metabolism/heart.svg';
import phone from '../../images/metabolism/phone.svg';
import time from '../../images/metabolism/time.svg';
import calendar from '../../images/metabolism/calendar.svg';

import {TRAINING_PROGRAMS, TRAINING_DAYS, DIET_COUNT} from '../../constants';

const Metabolism5 = () => (
    <section className="metabolism-5">
        <Container>
            <Row>
                <Col className="col-12 col-md d-flex d-md-block text-center">
                    <img src={calendar} alt="" className="icon" />
                    <p>Ponad {TRAINING_DAYS} dni treningowych</p>
                </Col>
                <Col className="col-12 col-md d-flex d-md-block text-center">
                    <img src={heart} alt="" className="icon" />
                    <p>Moje {DIET_COUNT} diety do wyboru</p>
                </Col>
                <Col className="col-12 col-md d-flex d-md-block text-center">
                    <img src={phone} alt="" className="icon" />
                    <p>{TRAINING_PROGRAMS} programów treningowe</p>
                </Col>
                <Col className="col-12 col-md d-flex d-md-block text-center">
                    <img src={time} alt="" className="icon" />
                    <p>Maksymalnie podkręcony metabolizm</p>
                </Col>
            </Row>
        </Container>
    </section>
); 
   
export default Metabolism5;
