import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import apple from '../../images/metabolism/apple.svg';
import workout from '../../images/metabolism/workout.svg';
import diet from '../../images/metabolism/diet.svg';

const Metabolism3 = () => (
    <section className="metabolism-3">
        <Container>
            <h2 class="text-center">Dlatego polecam Ci właśnie zestaw Fat Burner!</h2>
            <br/>
            <Row>
                <Col md="4">
                    <img src={apple} alt="dieta" className="icon" />
                    <h3>DIETA</h3>
                    <p>podstawa szczupłej sylwetki. Prawidłowo dobrana daje siłę do treningów!</p>
                </Col>
                <Col md="4">
                    <img src={workout} alt="treningi" className="icon" />
                    <h3>TRENINGI</h3>
                    <p>regulują nastrój i odstresowują, a&nbsp;także rzeźbią mięśnie w&nbsp;naszym ciele.</p>
                </Col>
                <Col md="4">
                    <img src={diet} alt="metabolizm" className="icon" />
                    <h3>METABOLIZM</h3>
                    <p>wyjątkowy suplement, który dba o przemianę materii w dzień i&nbsp;w&nbsp;nocy. Z nim wzmocnisz efekty diety i treningów!</p>
                </Col>
            </Row>
        </Container>
    </section>
); 
   
export default Metabolism3;
