import React from "react";
import '../helpers/suppress-install-prompt';
import Seo from "../components/seo";
import Layout from "../components/Layout";

import Hero from "../components/Metabolism/Hero";
import Metabolism1 from "../components/Metabolism/Metabolism1";
import Metabolism2 from "../components/Metabolism/Metabolism2";
import Metabolism3 from "../components/Metabolism/Metabolism3";
import Metabolism4 from "../components/Metabolism/Metabolism4";
import Metabolism5 from "../components/Metabolism/Metabolism5";
import Metabolism6 from "../components/Metabolism/Metabolism6";
import Metabolism7 from "../components/Metabolism/Metabolism7";
import Metabolism8 from "../components/Metabolism/Metabolism8";
import Metabolism9 from "../components/Metabolism/Metabolism9";

import SaleProductsList from "../components/SaleProduct/SaleProductsList";
import SaleProductsGroup from "../SaleProduct/SaleProductsGroup";

import { graphql } from "gatsby";

const Page = ({ data }) => {
    const productsGroups = [
        new SaleProductsGroup("landing:metabolizm", "", "", "red", true)
    ];

    return (
        <Layout page="metabolism" ctaProductName="metabolizm" showMenu={true}>
            <Seo
                title="Naturalne wsparcie metabolizmu"
                description="Fat Burner to naturalny zestaw stworzony z myślą o Tobie. Zbilansowana dieta, dobry trening i naturalny suplement - Metabolizm to gwarancja najlepszych efektów!"
            >
                <meta name="robots" content="noindex,nofollow" />
            </Seo>
            <Hero hero={data.hero.childImageSharp.gatsbyImageData} />
            <Metabolism1 meta={data.meta.childImageSharp.gatsbyImageData} />
            <Metabolism2 />
            <Metabolism3 />
            <Metabolism4
                body={data.body.childImageSharp.gatsbyImageData}
                mind={data.mind.childImageSharp.gatsbyImageData}
                libido={data.libido.childImageSharp.gatsbyImageData}
            />
            <Metabolism5 />
            <Metabolism6 />
            <Metabolism7 />
            <Metabolism8 metabolism={data.metabolism.childImageSharp.gatsbyImageData} />
            <Metabolism9 app={data.app.childImageSharp.gatsbyImageData} />
            <SaleProductsList productsGroups={productsGroups} />
        </Layout>
    );
};

export default Page

export const query = graphql`
    query {
        hero: file(relativePath: { eq: "metabolism/image-anna-skaczaca.jpg" }) {
            ...fluidImage1920
        }

        meta: file(relativePath: { eq: "metabolism/meta.png" }) {
            ...fluidImage480
        }

        body: file(relativePath: { eq: "homepage/body.png" }) {
            ...fluidImage480
        }

        mind: file(relativePath: { eq: "homepage/mind.png" }) {
            ...fluidImage480
        }

        libido: file(relativePath: { eq: "homepage/libido.png" }) {
            ...fluidImage480
        }

        metabolism: file(relativePath: { eq: "metabolism/metabolism.png" }) {
            ...fluidImage480
        }

        app: file(relativePath: { eq: "metabolism/app.png" }) {
            ...fluidImage480
        }
    }
`;
