import React from 'react';
import { Container } from 'reactstrap';

const Metabolism2 = () => (
    <section className="metabolism-2">
        <Container>
            <p>CZY WIESZ, ŻE…</p>
            <h2>Zbilansowana dieta, dobry trening i naturalny suplement - Metabolizm to gwarancja najlepszych efektów?</h2>
        </Container>
    </section>
); 
   
export default Metabolism2;
