import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import level_white from '../../images/icons/level_white.svg';
import { GatsbyImage } from "gatsby-plugin-image";
import scrollToElement from '../../helpers/scrollToElement';

const Metabolism8 = (props) => (
    <section className="metabolism-8">
        <Container>
            <h2 className="text-center">Z zestawem Fat Burner odkryjesz na co naprawdę Cię stać!</h2>
        </Container>

        
        <div className="position-relative">
            <div className="half-red">
                <img src={level_white} className="d-none d-sm-block" height="184" alt="" />
            </div>
            
            <Container>
                <Row>
                    <Col xs="12" md="6">
                        <GatsbyImage image={props.metabolism} className="d-block d-md-none mb-5" />
                        <div className="text-block">
                            <h2>Metabolizm <span class="info">60 KAPSUŁEK</span></h2>        
                            <p>Wyjątkowy suplement diety, który wspomaga Twoją przemianę materii. Tworzą go dwie kapsułki o odrębnym składzie i działaniu - na dzień i na noc. Unikatowy skład opiera się na ekstraktach roślinnych oraz substancjach pochodzenia naturalnego, które od setek lat były używane do przywracania równowagi metabolicznej organizmu.</p>
                            <p>DZIEŃ: Kapsułka na dzień zawiera ekstrakty z: zielonej herbaty, papryczki chilli, kory cynamonowca oraz Berberis aristata. To właśnie one przyspieszą przemianę materii i wyregulują metabolizm, a jednocześnie obronią Cię przed szkodliwymi skutkami działania wolnych rodników i dodadzą energii.</p>
                            <p>NOC: Kapsułka na noc zawiera sprzężone dieny kwasu linolowego (CLA), ekstrakt z korzenia pokrzywy indyjskiej oraz ekstrakt z liści banaba. Wyciągi te będą stymulowały przemiany metaboliczne węglowodanów oraz tłuszczy podczas Twojego snu. Dzięki niej utrwalisz efekty, na które pracowałeś za dnia! Kapsułki są odpowiednie dla wegetarian i wegan.</p>
                            <Button
                                color="blue"
                                onClick={() =>
                                    scrollToElement(
                                        document.getElementById('buy')
                                    )
                                }
                            >
                                Kup teraz
                            </Button>
                        </div>
                    </Col>
                    <Col xs="12" md={{size: 5, offset: 1}}>
                        <GatsbyImage image={props.metabolism} className="d-none d-md-block" />
                    </Col>
                </Row>
            </Container>
        </div>

    </section>
); 
   
export default Metabolism8;
